<div hidden='true' aria-hidden='true'>
    <svg>
        <symbol viewBox="0 0 16 16" id="icon-clock-16">
            <path d="M9 5v2.59l1.7 1.71a1 1 0 0 1-1.41 1.41l-2-2A1 1 0 0 1 7 8V5a1 1 0 0 1 2 0Z"></path>
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 14A6 6 0 1 1 8 2a6 6 0 0 1 0 12Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-location-16">
            <path d="M10 7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0a7 7 0 0 0-5.525 11.299c1.183 1.518 2.91 2.994 4.926 4.502a1 1 0 0 0 1.198 0c2.017-1.508 3.743-2.984 4.926-4.502A7 7 0 0 0 8 0ZM3 7a5 5 0 1 1 8.947 3.07c-.909 1.166-2.25 2.37-3.947 3.675-1.698-1.305-3.038-2.509-3.947-3.675A4.974 4.974 0 0 1 3 7Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-phone-16">
            <path d="M4.222 2.178a.5.5 0 0 0-.708-.053L2.067 3.38c-.06.052-.067.1-.067.11.18 2.275 1.58 4.82 3.635 6.874C7.69 12.42 10.235 13.82 12.51 14c.009 0 .057-.007.11-.067l1.257-1.446a.5.5 0 0 0-.053-.709l-2.03-1.73-2.452.892a1 1 0 0 1-1.049-.233l-3-3a1 1 0 0 1-.232-1.05l.893-2.451-1.732-2.028ZM2.202.615a2.5 2.5 0 0 1 3.54.264l1.86 2.175c.39.457.504 1.09.298 1.654l-.742 2.035 2.099 2.1 2.037-.74a1.667 1.667 0 0 1 1.65.298l2.177 1.855a2.5 2.5 0 0 1 .265 3.543l-1.257 1.446c-.417.48-1.058.805-1.776.748-2.904-.228-5.87-1.951-8.132-4.214C1.958 9.517.235 6.552.006 3.648c-.056-.719.27-1.36.75-1.777L2.203.615Z"></path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-chevron-right-24">
            <path d="M17.707 11.293a1 1 0 0 1 .22.33l-.22-.33Zm-.001-.001-7.999-8a1 1 0 0 0-1.414 1.415L15.586 12l-7.293 7.293a1 1 0 1 0 1.414 1.414l8-8a.999.999 0 0 0 .22-1.083"></path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-chevron-left-24">
            <path d="m6.293 11.292 8-8a1 1 0 1 1 1.414 1.415L8.414 12l7.293 7.293a1 1 0 1 1-1.414 1.414l-8-8a.996.996 0 0 1 0-1.415Z"></path>
        </symbol>
        <symbol viewBox="0 0 12 12" id="icon-chevron-down-12">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.808 4.188a.625.625 0 0 1 .884 0L6 7.495l3.308-3.307a.625.625 0 1 1 .884.885l-3.75 3.749a.625.625 0 0 1-.884 0l-3.75-3.749a.626.626 0 0 1 0-.885Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-star-dynamic">
            <!-- full star -->
            <path d="M8.596 1.928a.625.625 0 0 0-1.19 0L6.055 6.136H1.62a.625.625 0 0 0-.346 1.146l3.56 2.364-1.366 4.035a.625.625 0 0 0 .953.71L8 11.862l3.578 2.528a.625.625 0 0 0 .953-.71l-1.366-4.036 3.55-2.364a.625.625 0 0 0-.346-1.145H9.955l-1.36-4.207Z" fill="var(--color-star-rating-full, transparent)" stroke="var(--color-star-rating-full-stroke, #707070)" stroke-width="1.25"></path>
            <!-- full star/half star clipped -->
            <path d="M8.596 1.928a.625.625 0 0 0-1.19 0L6.055 6.136H1.62a.625.625 0 0 0-.346 1.146l3.56 2.364-1.366 4.035a.625.625 0 0 0 .953.71L8 11.862l3.578 2.528a.625.625 0 0 0 .953-.71l-1.366-4.036 3.55-2.364a.625.625 0 0 0-.346-1.145H9.955l-1.36-4.207Z" fill="var(--color-star-rating-half, transparent)" stroke="var(--color-star-rating-half-stroke, #707070)" stroke-width="1.25" clip-path="polygon(0 0, 50% 0, 50% 100%, 0 100%)"></path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-store-24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.2 3.4A1 1 0 0 1 6 3h12a1 1 0 0 1 .8.4l1.8 2.4A2 2 0 0 1 21 7v2c0 .268-.126.487-.423.681-.321.21-.752.319-1.077.319-.325 0-.756-.108-1.077-.319C18.126 9.487 18 9.268 18 9a1 1 0 1 0-2 0c0 .268-.126.487-.423.681-.321.21-.752.319-1.077.319-.325 0-.756-.108-1.077-.319C13.126 9.487 13 9.268 13 9a1 1 0 1 0-2 0c0 .268-.126.487-.423.681-.321.21-.752.319-1.077.319-.325 0-.756-.108-1.077-.319C8.126 9.487 8 9.268 8 9a1 1 0 0 0-2 0c0 .268-.126.487-.423.681-.321.21-.752.319-1.077.319-.325 0-.756-.108-1.077-.319C3.126 9.487 3 9.268 3 9V7a2 2 0 0 1 .4-1.2l1.8-2.4ZM1 9V7a4 4 0 0 1 .8-2.4l1.8-2.4A3 3 0 0 1 6 1h12a3 3 0 0 1 2.4 1.2l1.8 2.4A4 4 0 0 1 23 7v2c0 .935-.441 1.637-1 2.11V20a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-8.89C1.441 10.637 1 9.935 1 9Zm5.673 2.354A3.33 3.33 0 0 0 7 11.11c.106.09.215.171.327.244A4.103 4.103 0 0 0 9.5 12a4.14 4.14 0 0 0 1.5-.297V16H4v-4.034c.172.023.34.034.5.034a4.1 4.1 0 0 0 2.173-.646Zm10 0A3.33 3.33 0 0 0 17 11.11c.106.09.215.171.327.244A4.104 4.104 0 0 0 19.5 12c.16 0 .328-.011.5-.034V20a1 1 0 0 1-1 1h-6v-9.297a4.126 4.126 0 0 0 1.5.297 4.1 4.1 0 0 0 2.173-.646ZM4 20v-2h7v3H5a1 1 0 0 1-1-1Zm10-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-2Z"></path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-calendar-24">
            <path d="M6 10a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H6Zm4 1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Zm6-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2Z"></path>
            <path d="M5 2a1 1 0 0 1 2 0v1h10V2a1 1 0 1 1 2 0v1h3a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h3V2ZM3 21h18V8H3v13Z"></path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-wrench-24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.009 1H8c1.853 0 3.613.56 4.92 1.766C14.241 3.984 15 5.77 15 8.008v2.588l6.968 6.968c1.23 1.23 1.45 3.273.17 4.555-1.33 1.333-3.326 1.062-4.597-.2l-6.953-6.903H8c-2.236 0-4.019-.76-5.235-2.082C1.56 11.624 1 9.862 1 8.008V6.029a1.041 1.041 0 0 1 .483-.88c.322-.195.737-.19 1.054.012l4.308 2.588.896-.897-2.594-4.329a1.03 1.03 0 0 1-.02-1.012A1.03 1.03 0 0 1 6.01 1Zm1.757 2.002H8c1.462 0 2.701.44 3.566 1.237.85.785 1.434 2.003 1.434 3.769v2.98c-.006.28.112.558.31.747l7.244 7.244c.597.598.528 1.366.17 1.724-.55.55-1.282.283-1.775-.206l-7.237-7.186a1.005 1.005 0 0 0-.729-.297H8c-1.763 0-2.98-.585-3.764-1.437C3.44 10.712 3 9.471 3 8.008v-.234l3.48 2.09a1 1 0 0 0 1.242-.162l1.968-1.97a1.004 1.004 0 0 0 .154-1.263L7.766 3.002Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-attention-filled-16">
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 1 1-2 0V5a1 1 0 0 1 2 0v3Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-sort-12">
            <path d="M4 3.001a.75.75 0 0 1 .75.75v6.544l.94-1.046a.75.75 0 1 1 1.116 1.003L4.569 12.74a.75.75 0 0 1-1.137 0l-2.24-2.487a.75.75 0 1 1 1.115-1.004l.943 1.047V3.752a.75.75 0 0 1 .75-.75Zm6.308 3.755a.75.75 0 1 1-1.116-1.002l2.25-2.505a.75.75 0 0 1 1.18.081l2.184 2.418a.75.75 0 1 1-1.112 1.006L12.75 5.71v6.541a.75.75 0 0 1-1.5 0V5.707l-.942 1.05Z"></path>
        </symbol>
        <symbol viewBox="0 0 64 64" id="icon-attention-64">
            <path d="M32 17a2.5 2.5 0 0 1 2.5 2.5v15a2.5 2.5 0 0 1-5 0v-15A2.5 2.5 0 0 1 32 17Zm2.5 27.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"></path>
            <path fill-rule="evenodd" d="M62 32c0 16.569-13.431 30-30 30C15.431 62 2 48.569 2 32 2 15.431 15.431 2 32 2c16.569 0 30 13.431 30 30Zm-5 0c0 13.807-11.193 25-25 25S7 45.807 7 32 18.193 7 32 7s25 11.193 25 25Z" clip-rule="evenodd"></path>
        </symbol>
        <symbol viewbox="0 0 24 24" id="icon-lightbulb-24">
            <path fill-rule="evenodd" d="m8.039 18.279-.035-.105c-.226-.68-.357-1.072-.574-1.525-.232-.486-.574-1.063-1.262-2.094l-.052-.078C5.148 13.026 4 11.304 4 9a8 8 0 1 1 16 0c0 2.304-1.148 4.026-2.116 5.477l-.052.078c-.688 1.031-1.03 1.608-1.262 2.094-.217.453-.348.845-.574 1.525l-.035.105-.612 2.449A3 3 0 0 1 12.439 23h-.877a3 3 0 0 1-2.91-2.272l-.613-2.45ZM6 9a6 6 0 1 1 12 0c0 1.654-.81 2.913-1.832 4.445-.699 1.049-1.107 1.722-1.403 2.341-.197.412-.34.792-.485 1.214H13v-5.586l1.707-1.707a1 1 0 0 0-1.414-1.414L12 9.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L11 11.414V17H9.72a10.93 10.93 0 0 0-.485-1.214c-.296-.619-.704-1.292-1.403-2.34C6.811 11.912 6 10.653 6 9Zm7.72 10h-3.44l.311 1.243a1 1 0 0 0 .97.757h.877a1 1 0 0 0 .97-.757L13.72 19Z" clip-rule="evenodd">
            </path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-information-filled-24">
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0Zm-1 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1 3a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-information-filled-16">
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm1 11a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v3ZM8 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-information-24">
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0Zm0 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10Zm-1-11a1 1 0 1 1 2 0v6a1 1 0 1 1-2 0v-6Zm1-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-information-16">
            <path d="M8 7a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V8a1 1 0 0 0-1-1Zm1-2a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
            <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm2 0a6 6 0 1 0 12 0A6 6 0 0 0 2 8Z"></path>
        </symbol>
        <symbol viewBox="0 0 20 20" id="icon-external-link-20">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V4.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L15.586 3H13a1 1 0 0 1-1-1ZM5 3a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3v-4a1 1 0 1 0-2 0v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4a1 1 0 0 0 0-2H5Z"></path>
        </symbol>
        <symbol viewBox="0 0 16 16" id="icon-external-link-16">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.586 3 7.293 7.293a1 1 0 0 0 1.414 1.414L13 4.414V6a1 1 0 1 0 2 0V1.997A.997.997 0 0 0 14 1h-4a1 1 0 1 0 0 2h1.586ZM1 5.5A2.5 2.5 0 0 1 3.5 3H6a1 1 0 0 1 0 2H3.5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V10a1 1 0 1 1 2 0v2.5a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 1 12.5v-7Z"></path>
        </symbol>
        <symbol viewBox="0 0 24 24" id="icon-external-link-24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 2a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V5.414l-8.293 8.293a1 1 0 0 1-1.414-1.414L18.586 4H16a1 1 0 1 1 0-2h5ZM5 6a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-6a1 1 0 1 1 2 0v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h7a1 1 0 1 1 0 2H5Z"></path>
        </symbol>                                                                                                               
    </svg>
</div>